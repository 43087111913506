import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/markdown-github.css";


const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-pink-600 hover:bg-pink-500 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

export default ({ data }) => {
  const title = data.service.frontmatter.title;
  const imageData = data.file.childImageSharp.fluid;
  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0 h-full growing">
      <SEO title={title} />

      <div
        className="min-h-screen h-full flex flex-col "
        
      >
        <BackgroundImage
        Tag="div" className="w-full h-40 sm:h-32 lg:h-56"  fluid={imageData}>


          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-auto">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight">{title}</h1>
            </div>
            <div className="mx-auto px-2 mb-64">
              <div className="flex flex-wrap mt-24 md:mt-24 lg:mt-26">
                <div className="markdown-body sm:text-sm">
                      <div dangerouslySetInnerHTML={{ __html: data.service.html }}></div>
                </div>
                <div className="container w-3/4 sm:w-7/12 lg:w-5/12 xl:1/4 mt-28 self-start">
                <div
                  className="bg-white rounded flex flex-col sm:flex-row items-start sm:items-center text-sm p-4"
                  style={{
                    boxShadow:
                      "0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)"
                  }}
                >
                  <div className=" flex-1  text-teal-900">
                  <h2 className="font-heading text-3xl">My Coaching Process</h2>
                  <ul className="p-4 list-disc">
                    <li>Clarify Meaningful Goals</li>
                    <li>Manage Change</li>
                    <li>Development</li>
                  </ul>
                  </div>
                  <div className="flex-initial mt-6 sm:mt-0">
                    <Link to="/coaching">
                      <StyledButton>Learn more...</StyledButton>
                    </Link>
                  </div>
                </div>
              </div>
              </div>
              </div>
          </div>

        </BackgroundImage>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    service: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        image
        title
      }
      html
    }
    file(relativePath: { eq: "services-page-compass-banner.jpg"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`;
